// import logo from './logo.svg';
import './App.scss';
import './assets/fonts/fonts.scss'
import FooterBlock from './components/FooterBlock';
import MainBlock from './components/MainBlock';

import MainHeader from './components/MainHeader';

function App() {
  return (
    <div className="App">
      <MainHeader />
      <MainBlock />
      <FooterBlock />
    </div>
  );
}

export default App;
