import { Col, Container, Row } from 'react-bootstrap';
import './MainBlock.scss';
import VideoBlock from './VideoBlock';
import { useState } from 'react';
import pageInfo from '../data/data.json'

function MainBlock() {

    const needHideVote = localStorage.getItem('isHideVoteEtoEstafetaFinal1') === 'true'
    const setHideVote = (newHideVote) => {
        localStorage.setItem('isHideVoteEtoEstafetaFinal1', newHideVote)
        setHideVoteBtns(newHideVote)
    }

    // const [isHideVoteBtns, setHideVoteBtns] = useState(needHideVote)
    const [isHideVoteBtns, setHideVoteBtns] = useState(true)

    const [currentVideo, setCurrentVideo] = useState('')

    function onPlay(url) {
        setCurrentVideo(url)
    }

    const onStop = () => {
        setCurrentVideo('')
    }

    const videos = pageInfo.videos

    return (
        <div className='main-block'>

            <Container>
                <div className='infoblock'>
                    <div className='text-block-with-arrow block1'>
                        <div className='arrow1-image arrow'></div>
                        <div className='text-block-with-arrow-texts'>
                            <p className='text1 text-left text-uppercase'>
                                Уважаемые <br />участники, зрители <br />и болельщики!
                            </p>
                            <p className='text2 text-left text-uppercase'>
                                I этап нашей эстафеты выходит <br />на финишную прямую!
                            </p>
                        </div>
                    </div>
                    <div>
                        <div className='wheel-image wheel wheel-anim'></div>
                    </div>
                </div>
            </Container>

            <div className='separator'>
                <div className='separator-image'></div>
            </div>

            <Container>
                <div className='infoblock'>
                    <div className='motanka-image motanka'></div>
                    <div className='text-block-with-arrow block2'>
                        <div className='arrow2-image arrow arrow2'></div>
                        <div className='text-block-with-arrow-texts'>
                            <p className='text1 text-right text-uppercase'>
                                Именно вам предстоит выбрать победителей
                            </p>
                            <p className='text2 text-right text-uppercase'>
                                Голосуйте за понравившуюся работу!
                            </p>
                            <p className='text3 text-right'>
                                11-14 марта
                            </p>
                        </div>
                    </div>
                </div>
            </Container>

            <div className='centered-block'>
                <div className='sun-big-stat-image'><div className='sun-big-anim-image sun-anim'></div></div>
                <div className='cock-image cock1'></div>
            </div>

            <Container>
                <div>
                    <p className='text1 text-uppercase'>
                        Заряжайтесь позитивными эмоциями и поддержите таланты!
                    </p>
                </div>
            </Container>

            <div className='video-container'>
                <div className='sun-left-image sun-left'></div>
                <Container>
                    {(videos.length <= 5) &&
                        <Row>
                            <Col>
                                {videos.map((item, index) => {
                                    return <VideoBlock
                                        video={item}
                                        currentVideo={currentVideo}
                                        onPlay={onPlay}
                                        onStop={onStop}
                                        isHideVoteBtns={isHideVoteBtns}
                                        setHideVote={setHideVote}
                                    />

                                })}
                            </Col>
                        </Row>
                    }
                    {(videos.length > 5) &&
                        videos.map((item, index) => {
                            if (index % 2 === 0) {
                                const v1 = item
                                const v2 = videos[index + 1]
                                return <Row className='video-row' key={index}>
                                    <Col className='video-col-1'>
                                        <VideoBlock
                                            video={v1}
                                            currentVideo={currentVideo}
                                            onPlay={onPlay}
                                            onStop={onStop}
                                            isHideVoteBtns={isHideVoteBtns}
                                            setHideVote={setHideVote}
                                        />
                                    </Col>
                                    {v2 !== undefined &&
                                        <Col>
                                            <VideoBlock
                                                video={v2}
                                                currentVideo={currentVideo}
                                                onPlay={onPlay}
                                                onStop={onStop}
                                                isHideVoteBtns={isHideVoteBtns}
                                                setHideVote={setHideVote}
                                            />
                                        </Col>
                                    }
                                </Row>
                            } else return null
                        })
                    }
                </Container>
            </div>


            <Container>
                <div>
                    <p className='text1 text-uppercase'>
                        Ваш голос может стать решающим!
                    </p>
                    <p className='text2'>
                        Проголосовать можно только один раз <br /> до 18.00 (мск) 14 марта 2024 г.
                    </p>
                </div>
            </Container>

            <div className='w-100 position-relative matryoshka-block'>
                <div className='matryoshka-image matryoshka'></div>
                <div className='sun-right-image sun-right'></div>
            </div>

            <Container>
                <div className='label1-image label1'></div>

                <div className='results-block'>
                    <div className='cock-image cock2'></div>
                    <p className='text1 text-uppercase vertical-center'>
                        подводим итоги!
                    </p>
                    <div className='arrow3-image arrow arrow3'></div>
                </div>
            </Container>

            <div className='separator'>
                <div className='separator-image'></div>
            </div>

            <div className='down-block'>
                <div className='sun-big-image sun-big'></div>
                <div className='label2-image label2'></div>
                <div className='samovar-image samovar'></div>
            </div>

        </div>
    );
}

export default MainBlock;