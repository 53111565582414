import { Container } from 'react-bootstrap';
import './FooterBlock.scss';

function FooterBlock() {

    const social = [
        {
            href: "https://t.me/s/AssociationSchool",
            icon: 'icon-telegram',
            text: ''
        }
    ]

    return (
        <footer className='footer-block'>
            <Container>
                <div className="footer-top">
                    <div className='d-flex flex-row align-items-start justify-content-around'>
                        {social.map((item, index) =>
                            <a key={index}
                                href={item.href}
                                target="noopener noreferrer">
                                <i className={item.icon}></i>
                                <div>
                                    {item.text}
                                </div>
                            </a>
                        )}
                        <div className='phone'>
                            <i className={'icon-phone'}></i>
                            <div>
                                8 (495) 911‑61‑11
                            </div>
                        </div>
                    </div>
                    {/* </Container> */}
                </div>
                {/* <Container className="footer-bottom"> */}
                <div className="footer-bottom footer-copyright">
                    &copy; «Центр Международного Сотрудничества Министерства Просвещения Российской Федерации», {new Date().getFullYear()} г.
                </div>
            </Container>
        </footer>
    );
}

export default FooterBlock;
