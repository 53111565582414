import ReactPlayer from 'react-player';
import './VideoBlock.scss';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { notification } from 'antd';

function VideoBlock(props) {
    const { video, currentVideo, onPlay, onStop, isHideVoteBtns, setHideVote } = props;

    const [api, contextHolder] = notification.useNotification();

    const url = process.env.PUBLIC_URL + '/video/' + video.file
    const title = video.title

    const [playState, setPlayState] = useState(url === currentVideo)

    const onStart = (e) => {
        onPlay(url)
    }

    const onEnded = (e) => {
        onStop()
    }

    useEffect(() => {
        setPlayState(url === currentVideo)
    }, [url, currentVideo])

    const doVote = async (e) => {
        console.log("click");
        console.log(video.variant);
        try {
            await fetch(
                "https://docs.google.com/forms/d/e/1FAIpQLSfPRp4330oEoNwuUrwfI-iIOewxX7aVisTWNmy9IszhFgZSFQ/formResponse?" +
                new URLSearchParams({
                    "entry.457123363": video.variant
                }),
                {
                    mode: "no-cors",
                }
            );
            api.success({
                message: "Голос принят",
            });

            // form.resetFields();
            // setOpen(false);
            setHideVote(true)
        } catch (e) {
            api.error({
                message: e.message,
            });
        }

    }

    return (

        <div className='video-block'>
            {contextHolder}
            <h3 className='text2'>
                {title}
            </h3>

            <ReactPlayer
                className="player"
                url={url}
                width="100%"
                height="100%"
                controls={true}
                playing={playState}
                muted={false}
                onStart={onStart}
                onPlay={onStart}
                onEnded={onEnded}
            />
            {!isHideVoteBtns &&
                <Button variant="primary" onClick={doVote} className='button-text'>Проголосовать за это видео</Button>
            }

        </div>
    );
}

export default VideoBlock;
