import { Container } from 'react-bootstrap';
import './MainHeader.scss';

function MainHeader() {
    return (
        <header className="main-header">
            <h1 className='d-none'>Творческая эстафета ВЕСЁЛАЯ МАСЛЕНИЦА</h1>
            <Container className='main-container'>
                <div className='logo-block'>
                    <a className='logo-minpros-image logo-item' href='https://edu.gov.ru/'> </a>
                    <a className='logo-arsa-image logo-item' href='https://cicedu.ru/%d0%b4%d0%b5%d1%8f%d1%82%d0%b5%d0%bb%d1%8c%d0%bd%d0%be%d1%81%d1%82%d1%8c-2/%d0%b0%d1%81%d1%81%d0%be%d1%86%d0%b8%d0%b0%d1%86%d0%b8%d1%8f-%d1%80%d1%83%d1%81%d1%81%d0%ba%d0%b8%d1%85-%d1%88%d0%ba%d0%be%d0%bb-%d0%b7%d0%b0-%d1%80%d1%83%d0%b1%d0%b5%d0%b6%d0%be%d0%bc/'> </a>
                    <a className='logo-cms-image logo-item' href='https://cicedu.ru/'> </a>
                </div>
                <div className='title-block'>
                    <div className='title-text1'>
                        весёлая
                    </div>
                    <div className='title-image title-block-image'>
                    </div>
                    <div className='title-text2'>
                        Творческая эстафета
                    </div>
                </div>
                <div className='right-block header-right-image'></div>
            </Container>
        </header >
    );
}

export default MainHeader;
